/* eslint-disable no-unused-expressions */
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/button-has-type */
import { Collapse, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";

import { SignIn, SignUp } from "./Auth";

const { Panel } = Collapse;

const { TabPane } = Tabs;

const LandingPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const history = useHistory();
  const [top, setTop] = useState(true);

  useEffect(() => {
    const scrollHandler = () => {
      window.pageYOffset > 10 ? setTop(false) : setTop(true);
    };
    window.addEventListener("scroll", scrollHandler);
    return () => window.removeEventListener("scroll", scrollHandler);
  }, [top]);

  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      <header
        className={`fixed w-full z-30 md:bg-opacity-90 transition duration-300 ease-in-out ${
          !top && "bg-white blur shadow-lg"
        }`}
      >
        <div className="max-w-6xl mx-auto px-5 sm:px-6">
          <div className="flex items-center justify-between h-16 md:h-20">
            {/* Site branding */}
            <ul className="flex flex-grow justify-start items-center">
              <li>
                <Link to="/" className="block" aria-label="Cruip">
                  <img alt="Floppy disk logo" src="/logo512.png" width="48" />
                </Link>
              </li>
              <li>
                <p className="ml-4">
                  Floppy Disk (a drop by{" "}
                  <a className="text-purple-800" href="https://dots.community">
                    Dots
                  </a>
                  )
                </p>
              </li>
            </ul>

            {/* Site navigation */}
            {/* <nav className="flex flex-grow">
              <ul className="flex flex-grow justify-end flex-wrap items-center">
                <li>
                  <Link
                    to="/signin"
                    className="font-medium text-gray-600 hover:text-gray-900 px-5 py-3 flex items-center transition duration-150 ease-in-out"
                  >
                    Sign in
                  </Link>
                </li>
                <li>
                  <Link
                    to="/signup"
                    className="btn-sm text-gray-200 bg-gray-900 hover:bg-gray-800 ml-3"
                  >
                    <span>Sign up</span>
                    <svg
                      className="w-3 h-3 fill-current text-gray-400 flex-shrink-0 ml-2 -mr-1"
                      viewBox="0 0 12 12"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z"
                        fillRule="nonzero"
                      />
                    </svg>
                  </Link>
                </li>
              </ul>
            </nav> */}
          </div>
        </div>
      </header>
      <main className="flex-grow">
        {" "}
        <section className="relative">
          {/* Illustration behind hero content
          <div
            className="absolute left-1/2 transform -translate-x-1/2 bottom-0 pointer-events-none"
            aria-hidden="true"
          >
            <svg
              width="1360"
              height="578"
              viewBox="0 0 1360 578"
              xmlns="http://www.w3.org/2000/svg"
            >
              <defs>
                <linearGradient
                  x1="50%"
                  y1="0%"
                  x2="50%"
                  y2="100%"
                  id="illustration-01"
                >
                  <stop stopColor="#FFF" offset="0%" />
                  <stop stopColor="#EAEAEA" offset="77.402%" />
                  <stop stopColor="#DFDFDF" offset="100%" />
                </linearGradient>
              </defs>
              <g fill="url(#illustration-01)" fillRule="evenodd">
                <circle cx="1232" cy="128" r="128" />
                <circle cx="155" cy="443" r="64" />
              </g>
            </svg>
          </div> */}
          <div className="z-20 max-w-6xl mx-auto px-4 sm:px-6">
            {/* Hero content */}
            <div className="pt-32 pb-12 md:pt-40 md:pb-20">
              {/* Section header */}
              <div className="mb-8 p-2 w-full flex flex-wrap">
                <div className="pb-12 md:pb-16 w-full md:w-full lg:w-1/2 text-center md:text-left lg:pt-10">
                  <h1
                    className="text-5xl md:text-5xl font-bold leading-tighter tracking-tighter mb-4"
                    data-aos="zoom-y-out"
                  >
                    Store important Slack threads{" "}
                    <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-purple-800">
                      easily
                    </span>
                  </h1>
                  <div className="max-w-3xl mx-auto">
                    <p
                      className="text-3xl text-gray-600 mb-8"
                      data-aos="zoom-y-out"
                      data-aos-delay="150"
                    >
                      Integrates with Notion, Airtable or Coda!
                    </p>
                    <div
                      className="max-w-xs mx-auto sm:max-w-none sm:flex sm:justify-center"
                      data-aos="zoom-y-out"
                      data-aos-delay="300"
                    >
                      {/* <div>
                        <button
                          type="button"
                          className="text-xl nes-btn is-primary"
                        >
                          Learn More
                        </button>
                      </div> */}
                    </div>
                  </div>
                </div>

                <div className="lg:w-1/2 bg-grey text-center md:w-full">
                  <div className="lg:px-20 md:px-4">
                    <Tabs defaultActiveKey="1" centered>
                      <TabPane
                        className="text-base px-10"
                        tab="Sign up"
                        key="1"
                      >
                        <SignUp />
                      </TabPane>
                      <TabPane
                        className="text-base px-10"
                        tab="Sign in"
                        key="2"
                      >
                        <SignIn />
                      </TabPane>
                    </Tabs>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="relative">
          {/* Section background (needs .relative class on parent and next sibling elements) */}
          {/* <div
            className="absolute inset-0 top-1/2 md:mt-24 lg:mt-0 bg-gray-900 pointer-events-none"
            aria-hidden="true"
          /> */}

          <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
            <div className="py-5 md:py-3">
              {/* Section header */}
              <div className="max-w-3xl mx-auto text-center pb-20 md:pb-10">
                <p className="h1 text-3xl">
                  Floppy disk is a Slack app to start saving threads now.
                </p>
                <p className="h1 mb-15 text-gray-500 text-3xl">
                  No more copy-paste or screenshots of favorite Slack threads.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="relative">
          {/* Section background (needs .relative class on parent and next sibling elements) */}
          <div
            className="absolute inset-0 md:mt-24 lg:mt-0 bg-gradient-to-r from-blue-500 to-purple-800 pointer-events-none"
            aria-hidden="true"
          />

          <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
            <div className="py-10 md:py-10">
              {/* Section header */}
              <div className="max-w-3xl mx-auto text-center pb-8">
                <h1 className="h1 mb-4 text-4xl text-white">Integrates with</h1>
              </div>

              {/* Items */}
              <div className="max-w-sm mx-auto grid gap-6 md:grid-cols-2 lg:grid-cols-3 items-start md:max-w-2xl lg:max-w-none">
                {/* 1st item */}
                <div className="relative flex flex-col items-center p-6 bg-white rounded shadow-xl">
                  <img alt="notion" src="/notion.png" width="56" />
                  <h4 className="text-xl font-bold leading-snug tracking-tight mb-1 mt-3">
                    Notion
                  </h4>
                  {/* <p className="text-gray-600 text-center">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </p> */}
                </div>

                {/* 2nd item */}
                <div className="relative flex flex-col items-center p-6 bg-white rounded shadow-xl">
                  <img alt="coda" src="/coda.png" width="56" />
                  <h4 className="text-xl font-bold leading-snug tracking-tight mb-1 mt-3">
                    Coda
                  </h4>
                  <p className="text-gray-600 text-center" />
                </div>

                {/* 3rd item */}
                <div className="relative flex flex-col items-center p-6 bg-white rounded shadow-xl">
                  <img alt="Airtable" src="/airtable.png" width="56" />
                  <h4 className="text-xl font-bold leading-snug tracking-tight mb-1 mt-3">
                    Airtable
                  </h4>
                  {/* <p className="text-gray-600 text-center">(Coming soon!)</p> */}
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="relative">
          <div className="relative max-w-6xl mx-auto px-4 mt-10 sm:px-6">
            <div className="py-5 md:py-3">
              {/* Section header */}
              <div className="max-w-3xl mx-auto pb-5 ">
                <p className="ml-4 text-center text-2xl pb-10">FAQ</p>
                <Collapse>
                  <Panel
                    className="text-lg"
                    header="How do I store a thread?"
                    key="3"
                  >
                    <p className="text-sm">
                      Once the app is successfully installed, you can click the
                      three dots next to any thread, and find a context menu
                      option Save thread. After this the thread will apear in
                      your chosen tool!
                    </p>
                  </Panel>
                  <Panel
                    className="text-lg"
                    header="Where is the data stored?"
                    key="1"
                  >
                    <p className="text-sm">
                      You can pick the integration once signed up, and the
                      threads will be saved at the designated table. Feel free
                      to create any additional views on top to present the data!
                    </p>
                  </Panel>
                  <Panel
                    className="text-lg"
                    header="What date is collected as part of this Slack app?"
                    key="2"
                  >
                    <p className="text-sm">
                      We only collect the parent thread, the responses and
                      reaction counts (along with names of the members who
                      interacted on the thread) for the specific thread you want
                      to save. No other data from your Slack workspace is
                      collected.
                    </p>
                  </Panel>
                  <Panel
                    className="text-lg"
                    header="Is it actually free?"
                    key="6"
                  >
                    <p className="text-sm">
                      Yes, we don&apos;t plan to charge for this initial
                      functionality. We will always have a free generious tier!
                      If you wan&apos;t additional functionality at the moment,
                      you can check out our product{" "}
                      <a
                        className="text-purple-800"
                        href="https://dots.community"
                      >
                        Dots
                      </a>
                    </p>
                  </Panel>
                  <Panel
                    className="text-lg"
                    header="Can anyone in the workspace save a thread?"
                    key="4"
                  >
                    <p className="text-sm">
                      At the moment, anyone can save a thread. We will shortly
                      add the support to designate specific team members to
                      save.
                    </p>
                  </Panel>
                  <Panel
                    className="text-lg"
                    header="Can I save the thread at multiple locations?"
                    key="5"
                  >
                    <p className="text-sm">
                      Currently, we only support one integration.
                    </p>
                  </Panel>
                </Collapse>
              </div>
            </div>
          </div>
        </section>
        <section className="relative">
          <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
            <div className="py-5 md:py-3">
              {/* Section header */}
              <div className="max-w-3xl mx-auto text-center pb-2 md:pb-1">
                <p className="ml-4 text-lg">
                  A drop by{" "}
                  <a className="text-purple-800" href="https://dots.community">
                    Dots
                  </a>
                  {" | "}
                  Help? Email us at floppy@dots.community
                  {" | "}
                  <a className="text-purple-800" href="/privacy">
                    Privacy policy
                  </a>
                </p>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default LandingPage;
