/* eslint-disable consistent-return */
import { Alert, Button, Card, Col, Layout, Row, Spin, Steps, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import supabase from "../../lib/supabase";
import { ADD_TO_SLACK_URL } from "../../utils/constants";

const { Step } = Steps;
const { Content } = Layout;
const { TabPane } = Tabs;

const SlackPermissions = () => {
  const [loading, setLoading] = useState(true);
  const [sessionExists, setSessionExists] = useState(false);
  const history = useHistory();

  const addToSlack = (
    <Row type="flex" justify="center" align="middle">
      <Col span={12}>
        <Steps current={0}>
          <Step title="Add to Slack" />
          <Step title="Set up destination" />
        </Steps>

        <h3 className="my-10">
          Welcome! Please click below to give us permissions to your workspace!{" "}
        </h3>
        <div className="grid justify-items-center">
          <a className="add-slack " href={`${ADD_TO_SLACK_URL}/auth/added`}>
            <img
              alt="Add to Slack"
              height="60"
              src="https://platform.slack-edge.com/img/add_to_slack.png"
              srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x"
            />
          </a>
        </div>
      </Col>

      <p className="text-gray-600 pt-20 text-sm">
        PS: We only collect data from messages that you explicity ask to store.
        Data from your workspace won&#39;t be used for any other purposes than
        the app itself. <strong>We will never sell your data.</strong>
      </p>
      <p className="text-gray-600 text-sm">
        All associated data will be deleted right away if you decide to delete
        the account!
      </p>
    </Row>
  );

  const NoSession = () => (
    <div>
      <Content align="middle">
        <Row style={{ marginTop: "100px" }}>
          <Col span={8} offset={8}>
            <Alert
              message="No session"
              description="We couldn't find a user session. Please contact us for support at hello@dots.community!"
              type="error"
            />
          </Col>
        </Row>
        <Row style={{ marginTop: "10px" }}>
          <Col span={8} offset={8}>
            <Button type="link" onClick={() => history.push(`/signin`)}>
              Back to Sign In{" "}
            </Button>
          </Col>
        </Row>
      </Content>
    </div>
  );

  const ShowPermissions = () => (
    <Row type="flex" justify="center" align="middle" style={{}}>
      <div
        className="nes-container is-rounded"
        style={{
          textAlign: "center",
          margin: "100px 200px",
        }}
      >
        <Row align="middle" justify="center">
          {addToSlack}
        </Row>
      </div>
    </Row>
  );

  const Loading = () => (
    <div>
      <Content align="middle">
        <Row align="middle" justify="center" className="pt-10">
          {/* <Spin /> */}
          {/* <Lottie options={defaultOptions} width={200} /> */}
          <img alt="Loading" src="/loading.gif" width="128" />
        </Row>
        <Row align="middle" justify="center" className="pt-2">
          <p>Loading...</p>
        </Row>
      </Content>
    </div>
  );

  const checkSession = async () => {
    const session = supabase.auth.session();
    if (session) {
      if (session.user) {
        setSessionExists(true);
        setLoading(false);
        console.log("in check session", session.user.email);
        window.analytics.identify(session.user.email);
      }
    } else {
      setSessionExists(false);
      setLoading(false);
    }
  };

  useEffect(() => {
    setTimeout(checkSession, 1000); // need setTimeout here because supaBase
    const { data: authListener } = supabase.auth.onAuthStateChange(
      async (event, session) => {
        checkSession();
      }
    );
  }, []);

  let showComponent = <Loading />;
  if (loading) {
    showComponent = <Loading />;
  } else if (sessionExists) {
    showComponent = <ShowPermissions />;
  } else {
    showComponent = <NoSession />;
  }

  return <div>{showComponent}</div>;
};

export default SlackPermissions;
