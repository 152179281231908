import { createClient } from "@supabase/supabase-js";

require("dotenv").config();

const { REACT_APP_SUPABASE_URL } = process.env;
const { REACT_APP_SUPABASE_KEY } = process.env;

const supabase = createClient(REACT_APP_SUPABASE_URL, REACT_APP_SUPABASE_KEY);

export default supabase;
