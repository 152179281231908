import {
  Button,
  Card,
  Col,
  Input,
  message,
  Radio,
  Row,
  Spin,
  Steps,
} from "antd";
import React, { useEffect, useState } from "react";
import { Link, Redirect, Route, Switch, useHistory } from "react-router-dom";

import supabase from "../../lib/supabase";
import { NOTION_URL } from "../../utils/constants";
import { logout } from "../../utils/general";

const { Step } = Steps;

const Overall = (props) => {
  const token = 'localStorage.getItem("auth-token")';
  const slackId = localStorage.getItem("slack-id");
  const teamId = localStorage.getItem("team-id");
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [isOnboarding, setOnboarding] = useState(true);
  const [floppyDestination, setFloppyDestination] = useState(null);
  const [notionWorkspace, setNotionWorkspace] = useState("");
  const [codaAPIKey, setCodaAPIKey] = useState("");
  const [codaURL, setCodaURL] = useState("");
  const [airtableAPIKey, setAirtableAPIKey] = useState("");
  const [airtableBase, setAirtableBase] = useState("");

  const loadFloppySettings = async () => {
    setLoading(true);
    const floppySettings = await supabase
      .from("teams")
      .select("floppy_settings")
      .eq("slack_id", teamId);
    if (floppySettings.data.length) {
      const floppyData = floppySettings.data[0].floppy_settings;
      if (floppyData) {
        setFloppyDestination(floppyData.destination);
        setNotionWorkspace(floppyData.notion_workspace_name);
        setCodaAPIKey(floppyData.coda_api_key);
        setCodaURL(floppyData.coda_url);
        setAirtableAPIKey(floppyData.airtable_api_key);
        setAirtableBase(floppyData.airtable_base);
        if (floppyData.destination === "coda") {
          if (floppyData.coda_api_key && floppyData.coda_url)
            setOnboarding(false);
        } else if (floppyData.destination === "airtable") {
          if (floppyData.airtable_api_key && floppyData.airtable_base)
            setOnboarding(false);
        } else if (floppyData.destination === "notion") {
          if (
            floppyData.notion_bot_id &&
            floppyData.notion_access_token &&
            floppyData.notion_workspace_name
          ) {
            setOnboarding(false);
          }
        }
      }
    }
    setLoading(false);
  };

  const onFloppyDestinationChanged = async (event) => {
    setFloppyDestination(event.target.value);
  };

  const onFloppyCredsChanged = async (key, event) => {
    const { value } = event.target;
    if (key === "coda_url") {
      setCodaURL(value);
    }
    if (key === "coda_api_key") {
      setCodaAPIKey(value);
    }
    if (key === "airtable_api_key") {
      setAirtableAPIKey(value);
    }
    if (key === "airtable_base") {
      setAirtableBase(value);
    }
    window.analytics.track("Settings changed");
  };

  const save = async () => {
    const newSettings = {
      destination: floppyDestination,
      coda_api_key: codaAPIKey,
      coda_url: codaURL,
      airtable_api_key: airtableAPIKey,
      airtable_base: airtableBase,
    };
    const { data, error } = await supabase
      .from("teams")
      .update({ floppy_settings: newSettings })
      .match({ slack_id: teamId });
    if (error) {
      console.log("error in updateFloppySettings", error);
      message.error("Error updating Floppy settings!");
      return error;
    }
    if (!isOnboarding) {
      message.success("Updated Floppy settings!");
    } else {
      setOnboarding(false);
    }
    window.analytics.track("Saved Coda or Airtable");
    return data;
  };

  // check if the user has a strip customer id
  useEffect(() => {
    loadFloppySettings();
  }, []);

  const codaInstructions = (
    <div className="pt-6">
      <a
        className="text-purple-800 underline hover:text-gray-900"
        target="_blank"
        href="https://www.notion.so/Floppy-Disk-Coda-instructions-c6aecaf915ee4029aba3254c9f56f432"
        rel="noreferrer"
      >
        See instructions here
      </a>
    </div>
  );

  const notionInstructions = (
    <div>
      <p className="text-base pt-6">
        1. Duplicate this{" "}
        <a
          className="text-purple-800 underline hover:text-gray-900"
          target="_blank"
          href="https://www.notion.so/Stored-slack-threads-Floppy-disk-9f4a1b28422c4d83bca571a878448fa1"
          rel="noreferrer"
        >
          page
        </a>{" "}
        to your Notion workspace.{" "}
        <p>
          2. click Add to Notion and select the page that you just duplicated.
        </p>
        <p>
          {" "}
          PS: Please don&apos;t change the existing column names (but you can
          add your own columns)
        </p>
      </p>
    </div>
  );

  const airtableInstructions = (
    <div className="pt-6">
      <a
        className="text-purple-800 underline hover:text-gray-900"
        target="_blank"
        href="https://www.notion.so/Floppy-Disk-Airtable-instructions-32e23acaee674e2ba4e52661c9bb4783"
        rel="noreferrer"
      >
        See instructions here
      </a>
    </div>
  );

  const integrationOptions = (
    <div className="text-center">
      <p className="text-base mb-4">Pick a destination for Floppy: </p>
      <p className="text-sm text-gray-600">
        We recommend Notion because it will be the easiest to setup!
      </p>
      <Radio.Group
        onChange={onFloppyDestinationChanged}
        value={floppyDestination}
      >
        <Radio className="text-lg" value="notion">
          Notion
        </Radio>
        <Radio className="text-lg" value="coda">
          Coda
        </Radio>
        <Radio className="text-lg" value="airtable">
          Airtable
        </Radio>
      </Radio.Group>
    </div>
  );

  const settings = (
    <div>
      {floppyDestination === "notion" ? (
        <div>
          <p className="text-center pt-5">
            {notionWorkspace ? (
              <p className="text-base text-gray-500">
                Currently added to workspace {notionWorkspace}{" "}
              </p>
            ) : null}
            <p>
              <a
                className="nes-btn is-primary my-10 "
                href={`${NOTION_URL}/auth/notion`}
              >
                Add to Notion
              </a>
            </p>
          </p>
        </div>
      ) : null}

      {floppyDestination === "coda" ? (
        <div className="my-10">
          <p>
            <p>
              Coda API Key:{" "}
              <Input
                className="nes-input"
                value={codaAPIKey}
                onChange={(e) => onFloppyCredsChanged("coda_api_key", e)}
              />
            </p>
            <p>
              Coda Table URL:{" "}
              <Input
                className="nes-input"
                value={codaURL}
                onChange={(e) => onFloppyCredsChanged("coda_url", e)}
              />
            </p>
          </p>
          <button
            type="button"
            // className={`nes-btn ${(!codaURL || !codaAPIKey)?}`}
            className={`nes-btn is-primary my-5 bg-purple-800 ${
              !codaURL || !codaAPIKey ? "is-disabled" : ""
            }`}
            onClick={!codaURL || !codaAPIKey ? "" : save}
          >
            Save
          </button>
        </div>
      ) : null}

      {floppyDestination === "airtable" ? (
        <div className="my-10">
          <p>
            <p>
              Airtable API Key:{" "}
              <Input
                className="nes-input"
                value={airtableAPIKey}
                onChange={(e) => onFloppyCredsChanged("airtable_api_key", e)}
              />
            </p>
            <p>
              Airtable base:{" "}
              <Input
                className="nes-input"
                value={airtableBase}
                onChange={(e) => onFloppyCredsChanged("airtable_base", e)}
              />
            </p>
          </p>

          <button
            type="button"
            className={`nes-btn is-primary my-5 ${
              !airtableBase || !airtableAPIKey ? "is-disabled" : ""
            }`}
            onClick={!airtableBase || !airtableAPIKey ? "" : save}
          >
            Save
          </button>
        </div>
      ) : null}
    </div>
  );

  const onboardingContent = (
    <Spin spinning={loading}>
      <div
        className="nes-container is-rounded"
        style={{
          textAlign: "center",
          margin: "100px 200px",
        }}
      >
        <Steps current={1}>
          <Step title="Add to Slack" />
          <Step title="Set up destination" />
        </Steps>
        <div className="pt-12">
          {integrationOptions}
          {floppyDestination === "coda" ? codaInstructions : null}
          {floppyDestination === "airtable" ? airtableInstructions : null}
          {floppyDestination === "notion" ? notionInstructions : null}
          {settings}
        </div>
      </div>
    </Spin>
  );

  const homeContent = (
    <Spin spinning={loading}>
      <div className="mx-auto px-1">
        <div className="flex items-center justify-between">
          {/* Site branding */}
          <ul className="flex flex-grow justify-start flex-wrap items-center">
            <li>
              <Link to="/" className="block" aria-label="Cruip">
                <img alt="Loading" src="/logo512.png" width="32" />
              </Link>
            </li>
            <li>
              <p className="ml-4 text-sm">
                Floppy Disk (a drop by{" "}
                <a className="text-purple-800" href="https://dots.community">
                  Dots
                </a>
                )
              </p>
            </li>
          </ul>
          <ul className="flex flex-grow justify-end flex-wrap items-center">
            <li>
              <button
                onClick={() => {
                  logout();
                  history.push(`/`);
                }}
                type="button"
              >
                Logout{">"}
              </button>
            </li>
          </ul>
        </div>
      </div>

      <Row style={{ paddingBottom: 40, marginTop: 40 }} gutter={[32, 32]}>
        <Col span={12}>
          <div
            className="nes-container is-rounded"
            title="You're all set up!"
            bordered={false}
          >
            <p className="text-base">
              You can start saving threads by clicking the three dots next to a
              message and selecting the &quot;Save threads&quot; shortcut!
            </p>
            <img
              className="rounded-lg my-5"
              alt="instructions"
              src="/final-instruction.png"
              width="100%"
            />
            <p className="text-base">
              If you cannot find the option, click More mesasge shortcuts to
              find it there!
            </p>
          </div>
        </Col>

        <Col span={12}>
          <div className="nes-container is-rounded" title="Update settings">
            {integrationOptions}
            {settings}
            {floppyDestination === "coda" ? codaInstructions : null}
            {floppyDestination === "airtable" ? airtableInstructions : null}
            {floppyDestination === "notion" ? notionInstructions : null}
          </div>
        </Col>
      </Row>
    </Spin>
  );

  return (
    <Row type="flex" justify="center" align="middle" style={{}}>
      {isOnboarding ? onboardingContent : homeContent}
    </Row>
  );
};

export default Overall;
