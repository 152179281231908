/* eslint-disable consistent-return */
import { Layout } from "antd";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

import supabase from "../../lib/supabase";

const queryString = require("query-string");

const { Content } = Layout;

const Notion = (props) => {
  const params = props;
  const history = useHistory();
  const notionCode = queryString.parse(params.location.search).code;

  const handleRedirect = async () => {
    // confirm OAuth code with slack and return user token
    const response = await fetch(
      "https://gx18yoe5o4.execute-api.us-east-1.amazonaws.com/dev/addtonotion",
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          notionCode,
          reactAppURI: `${process.env.REACT_APP_URI}/auth/notion`,
          supaUser: supabase.auth.user(),
        }),
      }
    );
    const data = await response.json();
    if (!data.error) {
      history.push("/home");
    }
    return data;
  };

  useEffect(() => {
    handleRedirect();
  }, []);

  return (
    <Content align="middle" style={{ minHeight: "100vh" }}>
      <p>Loading...</p>
    </Content>
  );
};

export default Notion;
