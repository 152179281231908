/* eslint-disable import/prefer-default-export */

import supabase from "../lib/supabase";

const queryString = require("query-string");
// const team_id = localStorage.getItem('team-id')

const API_BASE_URL =
  "https://gx18yoe5o4.execute-api.us-east-1.amazonaws.com/dev/";

export const logout = () => {
  localStorage.removeItem("team-id");
  localStorage.removeItem("team-name");
  localStorage.removeItem("slack-id");
  supabase.auth.signOut().catch(console.error);
};

// Post to our backend Lambda functions as the currently logged in user
export const lambdaPost = async (body, endpoint) => {
  body.session = supabase.auth.session();
  const initObj = {
    method: "post",
    headers: new Headers({ "Content-Type": "application/json" }),
    body,
  };
  if (body) {
    initObj.body = JSON.stringify(body);
  }
  const apiUrlFormatted = API_BASE_URL + endpoint;
  return fetch(apiUrlFormatted, initObj)
    .then((response) => response.json())
    .then((responseJSON) => {
      return responseJSON;
    })
    .catch(function (err) {
      console.log("error in lambdaPost", err);
      return { error: err };
    });
};
