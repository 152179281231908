/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */

import { Alert, Button, Col, Layout, Row, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { Route, Switch, useHistory } from "react-router-dom";

import Notion from "./components/Notion/Notion";
import SlackAdded from "./components/SlackAdded/SlackAdded";
import SlackPermissions from "./components/SlackPermissions/SlackPermissions";
import supabase from "./lib/supabase";
import { SignIn, SignUp } from "./pages/Auth";
import Home from "./pages/Home";
import LandingPage from "./pages/LandingPage";
import Privacy from "./pages/Privacy";
import Terms from "./pages/Terms";

const { Content } = Layout;

const PrivateRoute = ({ component, ...options }) => {
  const [loading, setLoading] = useState(true);
  const [sessionExists, setSessionExists] = useState(false);
  const [workspaceExists, setWorkspaceExists] = useState(false);
  const history = useHistory();

  const checkSessionWorkspace = async () => {
    const session = supabase.auth.session();
    // check session
    if (session) {
      if (session.user) {
        const teamToken = localStorage.getItem("team-id");
        const userTeams = await supabase
          .from("users")
          .select("slack_id, team (slack_id, name)");
        const firstAppUser = userTeams.data[0];
        if (teamToken) {
          setWorkspaceExists(true);
          setSessionExists(true);
          setLoading(false);
        }
        // no team token
        else {
          if (userTeams.error) {
            console.log("error with userTeams", userTeams.error);
            setWorkspaceExists(false);
            setSessionExists(true);
            setLoading(false);
          }

          if (userTeams.data.length) {
            // set to first team returned for now
            const firstTeam = userTeams.data[0].team;
            if (firstTeam) {
              localStorage.setItem("team-id", userTeams.data[0].team.slack_id);
              localStorage.setItem("slack-id", userTeams.data[0].slack_id);
              localStorage.setItem("team-name", userTeams.data[0].team.name);
              window.analytics.identify(
                `${userTeams.data[0].team.name}-${userTeams.data[0].slack_id}`
              );
              window.analytics.track("Signed in", {
                slackId: `${userTeams.data[0].slack_id}`,
                teamId: `${userTeams.data[0].team.id}`,
                teamName: `${userTeams.data[0].team.name}`,
              });
              setWorkspaceExists(true);
              setSessionExists(true);
              setLoading(false);
            }
          }
          // no team found
          else {
            setWorkspaceExists(false);
            setSessionExists(true);
            setLoading(false);
          }
        }
      }
    } else {
      setSessionExists(false);
      setLoading(false);
    }
  };

  useEffect(() => {
    console.log("in useEffect");
    setTimeout(checkSessionWorkspace, 1000); // need setTimeout here because supaBase
    const { data: authListener } = supabase.auth.onAuthStateChange(
      async (event, session) => {
        checkSessionWorkspace();
      }
    );
  }, []);

  const Loading = () => (
    <div>
      <Content align="middle">
        <Row align="middle" justify="center" className="pt-10">
          {/* <Spin /> */}
          {/* <Lottie options={defaultOptions} width={200} /> */}
          <img alt="Loading" src="/loading.gif" width="128" />
        </Row>
        <Row align="middle" justify="center" className="pt-2">
          <p>Loading...</p>
        </Row>
      </Content>
    </div>
  );

  let finalComponent = LandingPage;
  if (loading) {
    finalComponent = () => <Loading />;
  } else if (sessionExists) {
    if (workspaceExists) {
      finalComponent = component;
    } else {
      finalComponent = SlackPermissions;
    }
  }

  return <Route {...options} component={finalComponent} />;
};
function App() {
  // let history = useHistory()
  const [user, setUser] = useState(null);

  useEffect(() => {
    const session = supabase.auth.session();
    setUser(session?.user ?? null);
    const { data: authListener } = supabase.auth.onAuthStateChange(
      async (event, sessionChange) => {
        const currentUser = sessionChange?.user;
        setUser(currentUser ?? null);
      }
    );

    return () => {
      authListener?.unsubscribe();
    };
  }, [user]);

  return (
    <>
      <Switch>
        <Route exact path="/welcome" component={LandingPage} />
        <Route exact path="/signup" component={SignUp} />
        <Route exact path="/signin" component={SignIn} />
        <Route exact path="/terms" component={Terms} />
        <Route exact path="/privacy" component={Privacy} />
        <Route path="/auth/permissions" component={SlackPermissions} />
        <Route exact path="/auth/added" component={SlackAdded} />
        <Route exact path="/auth/notion" component={Notion} />
        <PrivateRoute path="/" component={Home} />
      </Switch>
    </>
  );
}

export default App;
