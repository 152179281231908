import { Layout, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import Overall from "../components/Overall/Overall";

const { Content, Footer } = Layout;

const Home = (props) => {
  const teamId = localStorage.getItem("team-id");
  // eslint-disable-next-line no-shadow

  useEffect(() => {
    window.analytics.track("Home loaded");
  }, []);

  return (
    // <Spin spinning={!channels.length>0}>
    <Spin spinning={false}>
      <Layout style={{ minHeight: "100vh" }}>
        <Layout
          className="site-layout layout-full"
          style={{ background: "#FFFFFF" }}
        >
          {/* <Header className="site-layout-background" style={{ padding: 0 }} /> */}

          <Content style={{ margin: "40px 50px" }}>
            <Switch>
              <Route exact path="/" render={() => <Redirect to="/home" />} />
              <Route exact path="/home" component={Overall} />
            </Switch>
          </Content>
          <Footer
            style={{
              background: "#FFFFFF",
              textAlign: "center",
              display: "flex",
              justifyContent: "space-evenly",
            }}
          >
            Email floppy@dots.community or use chat for help!{" "}
          </Footer>
        </Layout>
      </Layout>
    </Spin>
  );
};

export default Home;
