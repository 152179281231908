/* eslint-disable consistent-return */
import { Alert, Button, Col, Layout, Row, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import supabase from "../../lib/supabase";

const queryString = require("query-string");

const { Content } = Layout;

const SlackAdded = (props) => {
  const [loading, setLoading] = useState(true);
  const [almostThere, setAlmostThere] = useState(false);
  const [slackError, setSlackError] = useState(false);
  const [databaseError, setDatabaseError] = useState(false);
  const params = props;
  const history = useHistory();

  const setLocalStorage = (data) => {
    localStorage.setItem("team-id", data.team.slack_id);
    localStorage.setItem("team-name", data.team.name);
    localStorage.setItem("slack-id", data.appUser.slack_id);
  };

  const setIdentifiers = (data) => {
    window.analytics.identify(`${data.team.name}-${data.appUser.slack_id}`);
    window.analytics.track("Signed up", {
      slackId: `${data.appUser.slack_id}`,
      teamId: `${data.team.id}`,
    });
    window.analytics.track("Slack Added", {
      slackId: `${data.appUser.slack_id}`,
      teamId: `${data.team.id}`,
    });
  };

  const handleRedirect = async () => {
    const slackCode = queryString.parse(params.location.search).code;
    const response = await fetch(
      "https://gx18yoe5o4.execute-api.us-east-1.amazonaws.com/dev/addtoslack",
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          slackCode,
          reactAppURI: `${process.env.REACT_APP_URI}/auth/added`,
          supaUser: supabase.auth.user(),
        }),
      }
    );
    const { data, error } = await response.json();
    setLoading(false);
    if (error) {
      console.log("error from /addtoslack", error);
      if (error === "slack_error") setSlackError(true);
      if (error === "database_error") setDatabaseError(true);
      return;
    }
    setLocalStorage(data);
    return history.push("/home");
  };
  // load once
  useEffect(() => {
    handleRedirect();
  }, []);

  return (
    <Content align="middle" style={{ minHeight: "100vh" }}>
      <Spin spinning={loading}>
        <Row
          type="flex"
          justify="center"
          align="middle"
          style={{ minHeight: "100vh" }}
        >
          <Col span={12}>
            <div>
              {slackError ? (
                <Alert
                  message="Slack Error"
                  description="We got an error trying to add Floppy disk to your Slack. Please go back to try again."
                  type="error"
                  action={
                    <Button
                      size="small"
                      danger
                      onClick={() => {
                        history.push("/");
                      }}
                    >
                      Go back
                    </Button>
                  }
                  showIcon
                />
              ) : null}
            </div>
            <div>
              {databaseError ? (
                <Alert
                  message="Database error"
                  description="We experienced an error setting up your account. Please contact floppy@dots.community."
                  type="error"
                  showIcon
                />
              ) : null}
            </div>
            <Row align="middle" justify="center" className="pt-10">
              <img alt="Loading" src="/loading.gif" width="128" />
            </Row>
            <Row align="middle" justify="center" className="pt-2">
              <h2>Authorizing...</h2>
            </Row>
            {/* <Lottie options={defaultOptions} width={400} /> */}

            {almostThere ? (
              <h2>Almost there! Redirecting you shortly..</h2>
            ) : (
              ""
            )}
            <Spin />
          </Col>
        </Row>
      </Spin>
    </Content>
  );
};

export default SlackAdded;
