import { Layout, Spin } from "antd";
import React, { useEffect, useState } from "react";

const { Content, Footer } = Layout;

const Privacy = (props) => {
  useEffect(() => {
    window.analytics.track("Privacy");
  }, []);

  return (
    // <Spin spinning={!channels.length>0}>
    <Spin spinning={false}>
      <Layout style={{ minHeight: "100vh" }}>
        <Layout
          className="site-layout layout-full"
          style={{ background: "#FFFFFF" }}
        >
          {/* <Header className="site-layout-background" style={{ padding: 0 }} /> */}

          <Content style={{ margin: "40px 50px", fontFamily: "sans-serif" }}>
            <h2 id="text17">Floppy Disk Privacy</h2>
            <span>
              Your privacy is important to us. It is Floppy Disk&#39;s policy to
              respect your privacy and comply with any applicable law and
              regulation regarding any personal information we may collect about
              you, including across our website, https://floppydisk.info, and
              other sites we own and operate.
            </span>
            <br />
            <br />{" "}
            <span>
              This policy is effective as of 15 May 2021 and was last updated on
              15 May 2021.
            </span>
            <br />
            <br /> <span>Information We Collect</span>
            <br />
            <br />{" "}
            <span>
              Information we collect includes both information you knowingly and
              actively provide us when using the app, and any information
              automatically sent by your devices in the course of accessing our
              products and services.
              <br />
              <p>
                When you or another user enables the Floppy Disk app for your
                team, we receive the name of your team, the team avatar and
                email domain list from Slack. User Information: If you are a
                member of the team that has enabled the app, we receive the
                username, first and last name, avatar, and email address from
                Slack. Messages: We only store messages directed at the Floppy
                disk app through the context menu in Slack. When a user clicks
                &quot;Save thread&quot;, we also receive the information about
                that user and the users that have responded to that thread
                (first name and last name)
              </p>
            </span>
            <br />
            <br /> <span>Log Data</span>
            <br />
            <br />{" "}
            <span>
              When you visit our website, our servers may automatically log the
              standard data provided by your web browser. It may include your
              device’s Internet Protocol (IP) address, your browser type and
              version, the pages you visit, the time and date of your visit, the
              time spent on each page, other details about your visit, and
              technical details that occur in conjunction with any errors you
              may encounter.
            </span>
            <br />
            <br />{" "}
            <span>
              Please be aware that while this information may not be personally
              identifying by itself, it may be possible to combine it with other
              data to personally identify individual persons.
            </span>
            <br />
            <br /> <span>Personal Information</span>
            <br />
            <br />{" "}
            <span>
              We may ask for personal information which may include one or more
              of the following:
            </span>
            <br />
            <br /> <span>Name</span>
            <br /> <span>Email</span>
            <br />
            <br />{" "}
            <span>
              Legitimate Reasons for Processing Your Personal Information
            </span>
            <br />
            <br />{" "}
            <span>
              We only collect and use your personal information when we have a
              legitimate reason for doing so. In which instance, we only collect
              personal information that is reasonably necessary to provide our
              services to you.
            </span>
            <br />
            <br /> <span>Collection and Use of Information</span>
            <br />
            <br />{" "}
            <span>
              We may collect personal information from you when you do any of
              the following on our website:
            </span>
            <br />
            <br />{" "}
            <span>
              Sign up to receive updates from us via email or social media
              channels
            </span>
            <br />{" "}
            <span>
              Use a mobile device or web browser to access our content
            </span>
            <br />{" "}
            <span>
              Contact us via email, social media, or on any similar technologies
            </span>
            <br /> <span>When you mention us on social media</span>
            <br />
            <br />{" "}
            <span>
              We may collect, hold, use, and disclose information for the
              following purposes, and personal information will not be further
              processed in a manner that is incompatible with these purposes:
            </span>
            <br />
            <br />{" "}
            <span>
              We may collect, hold, use, and disclose information for the
              following purposes, and personal information will not be further
              processed in a manner that is incompatible with these purposes:
            </span>
            <br />
            <br />{" "}
            <span>
              to enable you to customise or personalise your experience of our
              website
            </span>
            <br />
            <br /> <span>to contact and communicate with you</span>
            <br />{" "}
            <span>
              for analytics, market research, and business development,
              including to operate and improve our website, associated
              applications, and associated social media platforms
            </span>
            <br />{" "}
            <span>
              to enable you to access and use our website, associated
              applications, and associated social media platforms
            </span>
            <br />
            <br />{" "}
            <span>
              Please be aware that we may combine information we collect about
              you with general information or research data we receive from
              other trusted sources.
            </span>
            <br />
            <br /> <span>Security of Your Personal Information</span>
            <br />
            <br />{" "}
            <span>
              When we collect and process personal information, and while we
              retain this information, we will protect it within commercially
              acceptable means to prevent loss and theft, as well as
              unauthorized access, disclosure, copying, use, or modification.
            </span>
            <br />
            <br />{" "}
            <span>
              Although we will do our best to protect the personal information
              you provide to us, we advise that no method of electronic
              transmission or storage is 100% secure, and no one can guarantee
              absolute data security. We will comply with laws applicable to us
              in respect of any data breach.
            </span>
            <br />
            <br />{" "}
            <span>
              You are responsible for selecting any password and its overall
              security strength, ensuring the security of your own information
              within the bounds of our services.
            </span>
            <br />
            <br /> <span>How Long We Keep Your Personal Information</span>
            <br />
            <br />{" "}
            <span>
              We keep your personal information only for as long as we need to.
              This time period may depend on what we are using your information
              for, in accordance with this privacy policy. If your personal
              information is no longer required, we will delete it or make it
              anonymous by removing all details that identify you.
            </span>
            <br />
            <br />{" "}
            <span>
              However, if necessary, we may retain your personal information for
              our compliance with a legal, accounting, or reporting obligation
              or for archiving purposes in the public interest, scientific, or
              historical research purposes or statistical purposes.
            </span>
            <br />
            <br /> <span>Children’s Privacy</span>
            <br />
            <br />{" "}
            <span>
              We do not aim any of our products or services directly at children
              under the age of 13, and we do not knowingly collect personal
              information about children under 13.
            </span>
            <br />
            <br />{" "}
            <span>Disclosure of Personal Information to Third Parties</span>
            <br />
            <br /> <span>We may disclose personal information to:</span>
            <br />
            <br />{" "}
            <span>a parent, subsidiary, or affiliate of our company</span>
            <br />{" "}
            <span>
              third party service providers for the purpose of enabling them to
              provide their services, for example, IT service providers, data
              storage, hosting and server providers, advertisers, or analytics
              platforms
            </span>
            <br />{" "}
            <span>our employees, contractors, and/or related entities</span>
            <br />{" "}
            <span>our existing or potential agents or business partners</span>
            <br />{" "}
            <span>
              sponsors or promoters of any competition, sweepstakes, or
              promotion we run
            </span>
            <br />{" "}
            <span>
              courts, tribunals, regulatory authorities, and law enforcement
              officers, as required by law, in connection with any actual or
              prospective legal proceedings, or in order to establish, exercise,
              or defend our legal rights
            </span>
            <br />{" "}
            <span>
              third parties, including agents or sub-contractors, who assist us
              in providing information, products, services, or direct marketing
              to you
            </span>
            <br /> <span>third parties to collect and process data</span>
            <br />
            <br /> <span>International Transfers of Personal Information</span>
            <br />
            <br />{" "}
            <span>
              The personal information we collect is stored and/or processed
              where we or our partners, affiliates, and third-party providers
              maintain facilities. Please be aware that the locations to which
              we store, process, or transfer your personal information may not
              have the same data protection laws as the country in which you
              initially provided the information. If we transfer your personal
              information to third parties in other countries: (i) we will
              perform those transfers in accordance with the requirements of
              applicable law; and (ii) we will protect the transferred personal
              information in accordance with this privacy policy.
            </span>
            <br />
            <br />{" "}
            <span>Your Rights and Controlling Your Personal Information</span>
            <br />
            <br />{" "}
            <span>
              You always retain the right to withhold personal information from
              us, with the understanding that your experience of our website may
              be affected. We will not discriminate against you for exercising
              any of your rights over your personal information. If you do
              provide us with personal information you understand that we will
              collect, hold, use and disclose it in accordance with this privacy
              policy. You retain the right to request details of any personal
              information we hold about you.
            </span>
            <br />
            <br />{" "}
            <span>
              If we receive personal information about you from a third party,
              we will protect it as set out in this privacy policy. If you are a
              third party providing personal information about somebody else,
              you represent and warrant that you have such person’s consent to
              provide the personal information to us.
            </span>
            <br />
            <br />{" "}
            <span>
              If you have previously agreed to us using your personal
              information for direct marketing purposes, you may change your
              mind at any time. We will provide you with the ability to
              unsubscribe from our email-database or opt out of communications.
              Please be aware we may need to request specific information from
              you to help us confirm your identity.
            </span>
            <br />
            <br />{" "}
            <span>
              If you believe that any information we hold about you is
              inaccurate, out of date, incomplete, irrelevant, or misleading,
              please contact us using the details provided in this privacy
              policy. We will take reasonable steps to correct any information
              found to be inaccurate, incomplete, misleading, or out of date.
            </span>
            <br />
            <br />{" "}
            <span>
              If you believe that we have breached a relevant data protection
              law and wish to make a complaint, please contact us using the
              details below and provide us with full details of the alleged
              breach. We will promptly investigate your complaint and respond to
              you, in writing, setting out the outcome of our investigation and
              the steps we will take to deal with your complaint. You also have
              the right to contact a regulatory body or data protection
              authority in relation to your complaint.
            </span>
            <br />
            <br /> <span>Use of Cookies</span>
            <br />
            <br />{" "}
            <span>
              We use “cookies” to collect information about you and your
              activity across our site. A cookie is a small piece of data that
              our website stores on your computer, and accesses each time you
              visit, so we can understand how you use our site. This helps us
              serve you content based on preferences you have specified.
            </span>
            <br />
            <br /> <span>Limits of Our Policy</span>
            <br />
            <br />{" "}
            <span>
              Our website may link to external sites that are not operated by
              us. Please be aware that we have no control over the content and
              policies of those sites, and cannot accept responsibility or
              liability for their respective privacy practices.
            </span>
            <br />
            <br /> <span>Changes to This Policy</span>
            <br />
            <br />{" "}
            <span>
              At our discretion, we may change our privacy policy to reflect
              updates to our business processes, current acceptable practices,
              or legislative or regulatory changes. If we decide to change this
              privacy policy, we will post the changes here at the same link by
              which you are accessing this privacy policy.
            </span>
            <br />
            <br />{" "}
            <span>
              If required by law, we will get your permission or give you the
              opportunity to opt in to or opt out of, as applicable, any new
              uses of your personal information.
            </span>
            <br />
            <br /> <span>Contact Us</span>
            <br />
            <br />{" "}
            <span>
              For any questions or concerns regarding your privacy, you may
              contact us using the following details:
            </span>
            <br />
            <br /> <span>Sanket Chauhan</span>
            <p> sanket@dots.community </p>
            <br />
          </Content>
          <Footer
            style={{
              background: "#FFFFFF",
              textAlign: "center",
              display: "flex",
              justifyContent: "space-evenly",
            }}
          >
            Email floppy@dots.community or use chat for help!{" "}
          </Footer>
        </Layout>
      </Layout>
    </Spin>
  );
};

export default Privacy;
