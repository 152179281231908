import {
  GoogleOutlined,
  KeyOutlined,
  LockOutlined,
  MailOutlined,
  WindowsFilled,
} from "@ant-design/icons";
import { Input, message, Space } from "antd";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import supabase from "../lib/supabase";
import { logout } from "../utils/general";

const SignUp = () => {
  //
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const history = useHistory();

  const handleSignUp = async () => {
    logout();
    const { user, error } = await supabase.auth.signUp({ email, password });

    if (user) console.log("auth.js user", user);
    if (error) console.log("auth.js error", error);

    if (error) {
      console.log("in error section", error);
      message.error(`Error: ${error.message}`);
      return null;
    }
    if (!user && !error) {
      console.log("in email section");
      message.success(`"An email has been sent to you for verification!"`);
      return null;
    }
    // console.log(user);
    window.analytics.identify(user.email);
    window.analytics.track("Signed Up");
    return history.push("/auth/permissions");
  };

  const handleOAuthSignup = async () => {
    logout();
    // You need to enable the third party auth you want in Authentication > Settings
    // Read more on: https://supabase.io/docs/guides/auth#third-party-logins
    const { user, error } = await supabase.auth.signIn(
      {
        provider: "google",
      },
      {
        redirectTo: `${process.env.REACT_APP_URI}/auth/permissions`,
      }
    );
    window.analytics.track("Signed In");
    if (error) {
      console.log("Error: ", error.message);
      message.error(`Error: ${error.message}`);
      return null;
    }
    return null;
  };

  return (
    <Space style={{ width: "100%" }} size={12} direction="vertical">
      <Input
        className="nes-input mr-20"
        placeholder="Email"
        prefix={<MailOutlined />}
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        style={{ width: "100%", borderRadius: 5, marginTop: 10 }}
      />

      <Input.Password
        prefix={<KeyOutlined />}
        className="nes-input"
        placeholder="Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        style={{ width: "100%", borderRadius: 5 }}
      />

      <button
        style={{ borderRadius: 5 }}
        type="button"
        className="nes-btn is-primary hover:bg-purple-800  w-full text-base"
        icon={<LockOutlined />}
        block
        onClick={() => handleSignUp().catch(console.error)}
      >
        Sign up
      </button>

      <p>or</p>
      <button
        type="button"
        className="nes-btn  w-full text-base"
        style={{ borderRadius: 5, marginTop: 2 }}
        icon={<GoogleOutlined />}
        block
        onClick={() => handleOAuthSignup()}
      >
        Sign Up with Google
      </button>
    </Space>
  );
};

const SignIn = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const history = useHistory();

  const handleLogin = async () => {
    logout();
    const { user, error } = await supabase.auth.signIn({ email, password });

    if (user) console.log("auth.js user", user);
    if (error) console.log("auth.js error", error);

    if (error) {
      console.log("in error section", error);
      message.error(`Error: ${error.message}`);
      return null;
    }
    if (!user && !error) {
      console.log("in email section");
      message.success(`"An email has been sent to you for verification!"`);
      return null;
    }
    console.log("in redirect section");
    window.analytics.identify(user.email);
    window.analytics.track("Signed In");
    return history.push("/home");
  };

  const handleOAuthLogin = async () => {
    logout();
    const { user, error } = await supabase.auth.signIn(
      {
        provider: "google",
      },
      {
        redirectTo: `${process.env.REACT_APP_URI}/home`,
      }
    );
    window.analytics.track("Signed In");
    if (error) {
      console.log("Error: ", error.message);
      message.error(`Error: ${error.message}`);
      return null;
    }

    return history.push("/home");
  };

  // const forgotPassword = async (e) => {
  //   // Read more on https://supabase.io/docs/client/reset-password-email#notes
  //   e.preventDefault();
  //   const emailEntered = prompt("Please enter your email:");

  //   if (emailEntered === null || emailEntered === "") {
  //     message.error("You must enter your email.");
  //   } else {
  //     const { error } = await supabase.auth.api.resetPasswordForEmail(
  //       emailEntered
  //     );
  //     if (error) {
  //       console.error("Error: ", error.message);
  //     } else {
  //       message.success("Password recovery email has been sent.");
  //     }
  //   }
  // };

  return (
    <Space style={{ width: "100%" }} size={12} direction="vertical">
      <Input
        placeholder="Email"
        className="nes-input"
        prefix={<MailOutlined />}
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        style={{ width: "100%", borderRadius: 5, marginTop: 10 }}
      />

      <Input.Password
        prefix={<KeyOutlined />}
        placeholder="Password"
        className="nes-input"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        style={{ width: "100%", borderRadius: 5 }}
      />

      <button
        style={{ borderRadius: 5 }}
        icon={<LockOutlined />}
        block
        type="button"
        className="nes-btn is-primary w-full text-base"
        onClick={() => handleLogin()}
      >
        Sign In
      </button>
      <p>or</p>
      <button
        type="button"
        className="nes-btn  w-full text-base"
        style={{ borderRadius: 5, marginTop: 2 }}
        size="large"
        icon={<GoogleOutlined />}
        block
        onClick={() => handleOAuthLogin()}
      >
        Sign In with Google
      </button>
    </Space>
  );
};

export { SignIn, SignUp };
